import React from "react"
import Pages from "../../../components/SuperAdmin/SwiftLocal/updatePages"
import ReduxWrapper from "../../../redux/reduxWrapper"
import AdminPrivateRoute from "../adminPrivateRoute"

const UpdatePages = props => {
  return <AdminPrivateRoute component={Pages} location={props.location} selectedDocId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<UpdatePages {...props} />} />
export default WrappedPage

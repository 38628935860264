import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "../../css/admin_style.css"

import "@fontsource/noto-sans"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import AdminNavbar from "./adminNavbar"

const AdminLayout = ({ children }) => {
  return (
    <div>
      <AdminNavbar />
      <React.Fragment>{children}</React.Fragment>
    </div>
  )
}

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AdminLayout

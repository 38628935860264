import { navigate } from "gatsby"
import jwt_decode from "jwt-decode"

export const isBrowser = () => typeof window !== "undefined"

export const isAdminLoggedIn = () => {
  return !!(isBrowser() && window.localStorage.getItem("adminJwt"))
}

export const AdminLogout = () => {
  window.localStorage.removeItem("adminJwt")
  window.localStorage.removeItem("adminRefreshJwt")
  navigate("/superAdmin0rama/login")
  window.location.reload(true)
}

export const getAdminAccessToken = () => {
  return window.localStorage.getItem("adminJwt")
}

export const getLoginUserId = () => {
  if (isAdminLoggedIn()) {
    return window.localStorage.getItem("user_id")
  }

  return null
}

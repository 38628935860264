import React from "react"
import AdminLayout from "../../SuperAdminLayout/adminLayout"

const UpdatePages = () => {
  return (
    <AdminLayout>
      <section className="my-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <fieldset>
                <div className="mb-4">
                  <label className="form-label fw-bold">Single Vendor Detail Page</label>
                  <div className="input-group">
                    <span className="input-group-text">Contact ID from SwiftLocal account in SwiftCRM</span>
                    <input type="text" className="form-control" />
                    <button className="btn btn-primary">Update HTML</button>
                  </div>
                </div>
                <div className="mb-4">
                  <label className="form-label fw-bold">City-Category Page</label>
                  <div className="input-group">
                    <select className="form-select">
                      <option>== INDUSTRY ==</option>
                    </select>
                    <select className="form-select">
                      <option>== CITY ==</option>
                    </select>
                    <button className="btn btn-primary">Update HTML</button>
                  </div>
                </div>
                <div className="mb-4">
                  <label className="form-label fw-bold">Global-Category Page</label>
                  <div className="input-group">
                    <select className="form-select">
                      <option>== INDUSTRY ==</option>
                    </select>
                    <button className="btn btn-danger">Start Updater</button>
                  </div>
                </div>
                <div className="mb-4">
                  <label className="form-label fw-bold">City Page</label>
                  <div className="input-group">
                    <select className="form-select">
                      <option>== CITY ==</option>
                    </select>
                    <button className="btn btn-danger">Start Updater</button>
                  </div>
                </div>
                <div className="mb-4">
                  <label className="form-label fw-bold">Global</label>
                  <div className="input-group">
                    <button className="btn btn-danger">Start Updater</button>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </section>
    </AdminLayout>
  )
}

export default UpdatePages

import React from "react"
import { navigate } from "gatsby"
import { isBrowser, isAdminLoggedIn } from "../../services/adminAuth"

const AdminPrivateRoute = ({ component: Component, location, ...rest }) => {
  if (isBrowser()) {
    if (
      !isAdminLoggedIn() &&
      location.pathname !== `/superAdmin0rama/login` &&
      location.pathname !== `/superAdmin0rama`
    ) {
      navigate("/superAdmin0rama/login")
    } else {
      return <Component {...rest} />
    }
  }

  return <React.Fragment />
}

export default AdminPrivateRoute
